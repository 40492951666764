.container {
  width: 100%;
}

.fieldRow {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  
  :global {
    .t-input {
      flex: 1;
    }
  }
}