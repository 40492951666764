:root,:root[theme-mode="light"] {
  --brand-main: var(--td-brand-color-5);
  --td-brand-color-light: var(--td-brand-color-1);
  --td-brand-color-focus: var(--td-brand-color-2);
  --td-brand-color-disabled: var(--td-brand-color-3);
  --td-brand-color-hover: var(--td-brand-color-4);
  --td-brand-color: var(--td-brand-color-5);
  --td-brand-color-active: var(--td-brand-color-6);
  --td-brand-color-1: #fff0f6;
  --td-brand-color-2: #ffd8eb;
  --td-brand-color-3: #ffafda;
  --td-brand-color-4: #ff7aca;
  --td-brand-color-5: #ef45b3;
  --td-brand-color-6: #cb2a96;
  --td-brand-color-7: #a61379;
  --td-brand-color-8: #83005e;
  --td-brand-color-9: #620044;
  --td-brand-color-10: #44002e;
  --td-warning-color-1: #fef3e6;
  --td-warning-color-2: #f9e0c7;
  --td-warning-color-3: #f7c797;
  --td-warning-color-4: #f2995f;
  --td-warning-color-5: #ed7b2f;
  --td-warning-color-6: #d35a21;
  --td-warning-color-7: #ba431b;
  --td-warning-color-8: #9e3610;
  --td-warning-color-9: #842b0b;
  --td-warning-color-10: #5a1907;
  --td-warning-color: var(--td-warning-color-5);
  --td-warning-color-hover: var(--td-warning-color-4);
  --td-warning-color-focus: var(--td-warning-color-2);
  --td-warning-color-active: var(--td-warning-color-6);
  --td-warning-color-disabled: var(--td-warning-color-3);
  --td-warning-color-light: var(--td-warning-color-1);
  --td-error-color-1: #fdecee;
  --td-error-color-2: #f9d7d9;
  --td-error-color-3: #f8b9be;
  --td-error-color-4: #f78d94;
  --td-error-color-5: #f36d78;
  --td-error-color-6: #e34d59;
  --td-error-color-7: #c9353f;
  --td-error-color-8: #b11f26;
  --td-error-color-9: #951114;
  --td-error-color-10: #680506;
  --td-error-color: var(--td-error-color-6);
  --td-error-color-hover: var(--td-error-color-5);
  --td-error-color-focus: var(--td-error-color-2);
  --td-error-color-active: var(--td-error-color-7);
  --td-error-color-disabled: var(--td-error-color-3);
  --td-error-color-light: var(--td-error-color-1);
  --td-success-color-1: #e8f8f2;
  --td-success-color-2: #bcebdc;
  --td-success-color-3: #85dbbe;
  --td-success-color-4: #48c79c;
  --td-success-color-5: #00a870;
  --td-success-color-6: #078d5c;
  --td-success-color-7: #067945;
  --td-success-color-8: #056334;
  --td-success-color-9: #044f2a;
  --td-success-color-10: #033017;
  --td-success-color: var(--td-success-color-5);
  --td-success-color-hover: var(--td-success-color-4);
  --td-success-color-focus: var(--td-success-color-2);
  --td-success-color-active: var(--td-success-color-6);
  --td-success-color-disabled: var(--td-success-color-3);
  --td-success-color-light: var(--td-success-color-1);
  --td-gray-color-1: #f3f3f3;
  --td-gray-color-2: #eee;
  --td-gray-color-3: #e7e7e7;
  --td-gray-color-4: #dcdcdc;
  --td-gray-color-5: #c5c5c5;
  --td-gray-color-6: #a6a6a6;
  --td-gray-color-7: #8b8b8b;
  --td-gray-color-8: #777;
  --td-gray-color-9: #5e5e5e;
  --td-gray-color-10: #4b4b4b;
  --td-gray-color-11: #383838;
  --td-gray-color-12: #2c2c2c;
  --td-gray-color-13: #242424;
  --td-gray-color-14: #181818;
  --td-bg-color-container: #fff;
  --td-bg-color-container-select: #fff;
  --td-bg-color-page: var(--td-gray-color-2);
  --td-bg-color-container-hover: var(--td-gray-color-1);
  --td-bg-color-container-active: var(--td-gray-color-3);
  --td-bg-color-secondarycontainer: var(--td-gray-color-1);
  --td-bg-color-secondarycontainer-hover: var(--td-gray-color-2);
  --td-bg-color-secondarycontainer-active: var(--td-gray-color-4);
  --td-bg-color-component: var(--td-gray-color-3);
  --td-bg-color-component-hover: var(--td-gray-color-4);
  --td-bg-color-component-active: var(--td-gray-color-6);
  --td-bg-color-component-disabled: var(--td-gray-color-2);
  --td-component-stroke: var(--td-gray-color-3);
  --td-component-border: var(--td-gray-color-4);
  --td-font-white-1: #ffffff;
  --td-font-white-2: rgba(255, 255, 255, 0.55);
  --td-font-white-3: rgba(255, 255, 255, 0.35);
  --td-font-white-4: rgba(255, 255, 255, 0.22);
  --td-font-gray-1: rgba(0, 0, 0, 0.9);
  --td-font-gray-2: rgba(0, 0, 0, 0.6);
  --td-font-gray-3: rgba(0, 0, 0, 0.4);
  --td-font-gray-4: rgba(0, 0, 0, 0.26);
  /* 字体配置 */
  --td-font-family: PingFang SC, Microsoft YaHei, Arial Regular;
  --td-font-family-medium: PingFang SC, Microsoft YaHei, Arial Medium;
  --td-font-size-link-small: 12px;
  --td-font-size-link-medium: 14px;
  --td-font-size-link-large: 16px;
  --td-font-size-mark-small: 12px;
  --td-font-size-mark-medium: 14px;
  --td-font-size-body-small: 12px;
  --td-font-size-body-medium: 14px;
  --td-font-size-body-large: 16px;
  --td-font-size-title-small: 14px;
  --td-font-size-title-medium: 16px;
  --td-font-size-title-large: 20px;
  --td-font-size-headline-small: 24px;
  --td-font-size-headline-medium: 28px;
  --td-font-size-headline-large: 36px;
  --td-font-size-display-medium: 48px;
  --td-font-size-display-large: 64px;
  --td-line-height-common: 8px;
  --td-line-height-link-small: calc(    var(--td-font-size-link-small) + var(--td-line-height-common)  );
  --td-line-height-link-medium: calc(    var(--td-font-size-link-medium) + var(--td-line-height-common)  );
  --td-line-height-link-large: calc(    var(--td-font-size-link-large) + var(--td-line-height-common)  );
  --td-line-height-mark-small: calc(    var(--td-font-size-mark-small) + var(--td-line-height-common)  );
  --td-line-height-mark-medium: calc(    var(--td-font-size-mark-medium) + var(--td-line-height-common)  );
  --td-line-height-body-small: calc(    var(--td-font-size-body-small) + var(--td-line-height-common)  );
  --td-line-height-body-medium: calc(    var(--td-font-size-body-medium) + var(--td-line-height-common)  );
  --td-line-height-body-large: calc(    var(--td-font-size-body-large) + var(--td-line-height-common)  );
  --td-line-height-title-small: calc(    var(--td-font-size-title-small) + var(--td-line-height-common)  );
  --td-line-height-title-medium: calc(    var(--td-font-size-title-medium) + var(--td-line-height-common)  );
  --td-line-height-title-large: calc(    var(--td-font-size-title-medium) + var(--td-line-height-common)  );
  --td-line-height-headline-small: calc(    var(--td-font-size-headline-small) + var(--td-line-height-common)  );
  --td-line-height-headline-medium: calc(    var(--td-font-size-headline-medium) + var(--td-line-height-common)  );
  --td-line-height-headline-large: calc(    var(--td-font-size-headline-large) + var(--td-line-height-common)  );
  --td-line-height-display-medium: calc(    var(--td-font-size-display-medium) + var(--td-line-height-common)  );
  --td-line-height-display-large: calc(    var(--td-font-size-display-large) + var(--td-line-height-common)  );
  --td-font-link-small: var(--td-font-size-link-small) /    var(--td-line-height-link-small) var(--td-font-family);
  --td-font-link-medium: var(--td-font-size-link-medium) /    var(--td-line-height-link-medium) var(--td-font-family);
  --td-font-link-large: var(--td-font-size-link-large) /    var(--td-line-height-link-large) var(--td-font-family);
  --td-font-mark-small: 600 var(--td-font-size-mark-small) /    var(--td-line-height-mark-small) var(--td-font-family);
  --td-font-mark-medium: 600 var(--td-font-size-mark-medium) /    var(--td-line-height-mark-medium) var(--td-font-family);
  --td-font-body-small: var(--td-font-size-body-small) /    var(--td-line-height-body-small) var(--td-font-family);
  --td-font-body-medium: var(--td-font-size-body-medium) /    var(--td-line-height-body-medium) var(--td-font-family);
  --td-font-body-large: var(--td-font-size-body-large) /    var(--td-line-height-body-large) var(--td-font-family);
  --td-font-title-small: var(--td-font-size-title-small) /    var(--td-line-height-title-small) var(--td-font-family);
  --td-font-title-medium: var(--td-font-size-title-medium) /    var(--td-line-height-title-medium) var(--td-font-family);
  --td-font-title-large: var(--td-font-size-title-large) /    var(--td-line-height-title-large) var(--td-font-family);
  --td-font-headline-small: var(--td-font-size-headline-small) /    var(--td-line-height-headline-small) var(--td-font-family);
  --td-font-headline-medium: var(--td-font-size-headline-medium) /    var(--td-line-height-headline-medium) var(--td-font-family);
  --td-font-headline-large: var(--td-font-size-headline-large) /    var(--td-line-height-headline-large) var(--td-font-family);
  --td-font-display-medium: var(--td-font-size-display-medium) /    var(--td-line-height-display-medium) var(--td-font-family);
  --td-font-display-large: var(--td-font-size-display-large) /    var(--td-line-height-display-large) var(--td-font-family);
/* 字体颜色 */
  --td-text-color-primary: var(--td-font-gray-1);
  --td-text-color-secondary: var(--td-font-gray-2);
  --td-text-color-placeholder: var(--td-font-gray-3);
  --td-text-color-disabled: var(--td-font-gray-4);
  --td-text-color-anti: #fff;
  --td-text-color-brand: var(--td-brand-color);
  --td-text-color-link: var(--td-brand-color);
/* end 字体配置 */        /* 圆角配置 */
  --td-radius-small: 2px;
  --td-radius-default: 3px;
  --td-radius-medium: 6px;
  --td-radius-large: 9px;
  --td-radius-extraLarge: 12px;
  --td-radius-round: 999px;
  --td-radius-circle: 50%;
/* end 圆角配置 */    /* 阴影配置 */
  --td-shadow-1: 0 1px 10px rgba(0, 0, 0, .05),0 4px 5px rgba(0, 0, 0, 8%),0 2px 4px -1px rgba(0, 0, 0, 12%);
  --td-shadow-2: 0 3px 14px 2px rgba(0, 0, 0, .05),0 8px 10px 1px rgba(0, 0, 0, 6%),0 5px 5px -3px rgba(0, 0, 0, 10%);
  --td-shadow-3: 0 6px 30px 5px rgba(0, 0, 0, .05),0 16px 24px 2px rgba(0, 0, 0, 4%),0 8px 10px -5px rgba(0, 0, 0, 8%);
/* end 阴影配置 */     /* 尺寸配置 */
  --td-size-1: 2px;
  --td-size-2: 4px;
  --td-size-3: 6px;
  --td-size-4: 8px;
  --td-size-5: 12px;
  --td-size-6: 16px;
  --td-size-7: 20px;
  --td-size-8: 24px;
  --td-size-9: 28px;
  --td-size-10: 32px;
  --td-size-11: 36px;
  --td-size-12: 40px;
  --td-size-13: 48px;
  --td-size-14: 56px;
  --td-size-15: 64px;
  --td-size-16: 72px;
  --td-comp-size-xxxs: var(--td-size-6);
  --td-comp-size-xxs: var(--td-size-7);
  --td-comp-size-xs: var(--td-size-8);
  --td-comp-size-s: var(--td-size-9);
  --td-comp-size-m: var(--td-size-10);
  --td-comp-size-l: var(--td-size-11);
  --td-comp-size-xl: var(--td-size-12);
  --td-comp-size-xxl: var(--td-size-13);
  --td-comp-size-xxxl: var(--td-size-14);
  --td-comp-size-xxxxl: var(--td-size-15);
  --td-comp-size-xxxxxl: var(--td-size-16);
  --td-pop-padding-s: var(--td-size-2);
  --td-pop-padding-m: var(--td-size-3);
  --td-pop-padding-l: var(--td-size-4);
  --td-pop-padding-xl: var(--td-size-5);
  --td-pop-padding-xxl: var(--td-size-6);
  --td-comp-paddingLR-xxs: var(--td-size-1);
  --td-comp-paddingLR-xs: var(--td-size-2);
  --td-comp-paddingLR-s: var(--td-size-4);
  --td-comp-paddingLR-m: var(--td-size-5);
  --td-comp-paddingLR-l: var(--td-size-6);
  --td-comp-paddingLR-xl: var(--td-size-8);
  --td-comp-paddingLR-xxl: var(--td-size-10);
  --td-comp-paddingTB-xxs: var(--td-size-1);
  --td-comp-paddingTB-xs: var(--td-size-2);
  --td-comp-paddingTB-s: var(--td-size-4);
  --td-comp-paddingTB-m: var(--td-size-5);
  --td-comp-paddingTB-l: var(--td-size-6);
  --td-comp-paddingTB-xl: var(--td-size-8);
  --td-comp-paddingTB-xxl: var(--td-size-10);
  --td-comp-margin-xxs: var(--td-size-1);
  --td-comp-margin-xs: var(--td-size-2);
  --td-comp-margin-s: var(--td-size-4);
  --td-comp-margin-m: var(--td-size-5);
  --td-comp-margin-l: var(--td-size-6);
  --td-comp-margin-xl: var(--td-size-7);
  --td-comp-margin-xxl: var(--td-size-8);
  --td-comp-margin-xxxl: var(--td-size-10);
  --td-comp-margin-xxxxl: var(--td-size-12);
/* end 尺寸配置 */
}

:root[theme-mode="dark"] {
  --brand-main: var(--td-brand-color-6);
  --td-brand-color-light: var(--td-brand-color-1);
  --td-brand-color-focus: var(--td-brand-color-2);
  --td-brand-color-disabled: var(--td-brand-color-3);
  --td-brand-color-hover: var(--td-brand-color-5);
  --td-brand-color: var(--td-brand-color-6);
  --td-brand-color-active: var(--td-brand-color-7);
  --td-brand-color-1: #ef45b320;
  --td-brand-color-2: #620044;
  --td-brand-color-3: #83005e;
  --td-brand-color-4: #a61379;
  --td-brand-color-5: #cb2a96;
  --td-brand-color-6: #ef45b3;
  --td-brand-color-7: #ff7aca;
  --td-brand-color-8: #ffafda;
  --td-brand-color-9: #ffd8eb;
  --td-brand-color-10: #fff0f6;
  --td-warning-color-1: #4f2a1d;
  --td-warning-color-2: #582f21;
  --td-warning-color-3: #733c23;
  --td-warning-color-4: #a75d2b;
  --td-warning-color-5: #cf6e2d;
  --td-warning-color-6: #dc7633;
  --td-warning-color-7: #e8935c;
  --td-warning-color-8: #ecbf91;
  --td-warning-color-9: #eed7bf;
  --td-warning-color-10: #f3e9dc;
  --td-error-color-1: #472324;
  --td-error-color-2: #5e2a2d;
  --td-error-color-3: #703439;
  --td-error-color-4: #83383e;
  --td-error-color-5: #a03f46;
  --td-error-color-6: #c64751;
  --td-error-color-7: #de6670;
  --td-error-color-8: #ec888e;
  --td-error-color-9: #edb1b6;
  --td-error-color-10: #eeced0;
  --td-success-color-1: #193a2a;
  --td-success-color-2: #1a4230;
  --td-success-color-3: #17533d;
  --td-success-color-4: #0d7a55;
  --td-success-color-5: #059465;
  --td-success-color-6: #43af8a;
  --td-success-color-7: #46bf96;
  --td-success-color-8: #80d2b6;
  --td-success-color-9: #b4e1d3;
  --td-success-color-10: #deede8;
  --td-gray-color-1: #f3f3f3;
  --td-gray-color-2: #eee;
  --td-gray-color-3: #e7e7e7;
  --td-gray-color-4: #dcdcdc;
  --td-gray-color-5: #c5c5c5;
  --td-gray-color-6: #a6a6a6;
  --td-gray-color-7: #8b8b8b;
  --td-gray-color-8: #777;
  --td-gray-color-9: #5e5e5e;
  --td-gray-color-10: #4b4b4b;
  --td-gray-color-11: #383838;
  --td-gray-color-12: #2c2c2c;
  --td-gray-color-13: #242424;
  --td-gray-color-14: #181818;
  --td-bg-color-page: var(--td-gray-color-14);
  --td-bg-color-container: var(--td-gray-color-13);
  --td-bg-color-container-hover: var(--td-gray-color-12);
  --td-bg-color-container-active: var(--td-gray-color-10);
  --td-bg-color-container-select: var(--td-gray-color-9);
  --td-bg-color-secondarycontainer: var(--td-gray-color-12);
  --td-bg-color-secondarycontainer-hover: var(--td-gray-color-11);
  --td-bg-color-secondarycontainer-active: var(--td-gray-color-9);
  --td-bg-color-component: var(--td-gray-color-11);
  --td-bg-color-component-hover: var(--td-gray-color-10);
  --td-bg-color-component-active: var(--td-gray-color-9);
  --td-bg-color-component-disabled: var(--td-gray-color-12);
  --td-component-stroke: var(--td-gray-color-11);
  --td-component-border: var(--td-gray-color-9);
  --td-font-white-1: rgba(255, 255, 255, 0.9);
  --td-font-white-2: rgba(255, 255, 255, 0.55);
  --td-font-white-3: rgba(255, 255, 255, 0.35);
  --td-font-white-4: rgba(255, 255, 255, 0.22);
  --td-font-gray-1: rgba(0, 0, 0, 0.9);
  --td-font-gray-2: rgba(0, 0, 0, 0.6);
  --td-font-gray-3: rgba(0, 0, 0, 0.4);
  --td-font-gray-4: rgba(0, 0, 0, 0.26);
  /* 字体配置 */
  --td-font-family: PingFang SC, Microsoft YaHei, Arial Regular;
  --td-font-family-medium: PingFang SC, Microsoft YaHei, Arial Medium;
  --td-font-size-link-small: 12px;
  --td-font-size-link-medium: 14px;
  --td-font-size-link-large: 16px;
  --td-font-size-mark-small: 12px;
  --td-font-size-mark-medium: 14px;
  --td-font-size-body-small: 12px;
  --td-font-size-body-medium: 14px;
  --td-font-size-body-large: 16px;
  --td-font-size-title-small: 14px;
  --td-font-size-title-medium: 16px;
  --td-font-size-title-large: 20px;
  --td-font-size-headline-small: 24px;
  --td-font-size-headline-medium: 28px;
  --td-font-size-headline-large: 36px;
  --td-font-size-display-medium: 48px;
  --td-font-size-display-large: 64px;
  --td-line-height-common: 8px;
  --td-line-height-link-small: calc(    var(--td-font-size-link-small) + var(--td-line-height-common)  );
  --td-line-height-link-medium: calc(    var(--td-font-size-link-medium) + var(--td-line-height-common)  );
  --td-line-height-link-large: calc(    var(--td-font-size-link-large) + var(--td-line-height-common)  );
  --td-line-height-mark-small: calc(    var(--td-font-size-mark-small) + var(--td-line-height-common)  );
  --td-line-height-mark-medium: calc(    var(--td-font-size-mark-medium) + var(--td-line-height-common)  );
  --td-line-height-body-small: calc(    var(--td-font-size-body-small) + var(--td-line-height-common)  );
  --td-line-height-body-medium: calc(    var(--td-font-size-body-medium) + var(--td-line-height-common)  );
  --td-line-height-body-large: calc(    var(--td-font-size-body-large) + var(--td-line-height-common)  );
  --td-line-height-title-small: calc(    var(--td-font-size-title-small) + var(--td-line-height-common)  );
  --td-line-height-title-medium: calc(    var(--td-font-size-title-medium) + var(--td-line-height-common)  );
  --td-line-height-title-large: calc(    var(--td-font-size-title-medium) + var(--td-line-height-common)  );
  --td-line-height-headline-small: calc(    var(--td-font-size-headline-small) + var(--td-line-height-common)  );
  --td-line-height-headline-medium: calc(    var(--td-font-size-headline-medium) + var(--td-line-height-common)  );
  --td-line-height-headline-large: calc(    var(--td-font-size-headline-large) + var(--td-line-height-common)  );
  --td-line-height-display-medium: calc(    var(--td-font-size-display-medium) + var(--td-line-height-common)  );
  --td-line-height-display-large: calc(    var(--td-font-size-display-large) + var(--td-line-height-common)  );
  --td-font-link-small: var(--td-font-size-link-small) /    var(--td-line-height-link-small) var(--td-font-family);
  --td-font-link-medium: var(--td-font-size-link-medium) /    var(--td-line-height-link-medium) var(--td-font-family);
  --td-font-link-large: var(--td-font-size-link-large) /    var(--td-line-height-link-large) var(--td-font-family);
  --td-font-mark-small: 600 var(--td-font-size-mark-small) /    var(--td-line-height-mark-small) var(--td-font-family);
  --td-font-mark-medium: 600 var(--td-font-size-mark-medium) /    var(--td-line-height-mark-medium) var(--td-font-family);
  --td-font-body-small: var(--td-font-size-body-small) /    var(--td-line-height-body-small) var(--td-font-family);
  --td-font-body-medium: var(--td-font-size-body-medium) /    var(--td-line-height-body-medium) var(--td-font-family);
  --td-font-body-large: var(--td-font-size-body-large) /    var(--td-line-height-body-large) var(--td-font-family);
  --td-font-title-small: var(--td-font-size-title-small) /    var(--td-line-height-title-small) var(--td-font-family);
  --td-font-title-medium: var(--td-font-size-title-medium) /    var(--td-line-height-title-medium) var(--td-font-family);
  --td-font-title-large: var(--td-font-size-title-large) /    var(--td-line-height-title-large) var(--td-font-family);
  --td-font-headline-small: var(--td-font-size-headline-small) /    var(--td-line-height-headline-small) var(--td-font-family);
  --td-font-headline-medium: var(--td-font-size-headline-medium) /    var(--td-line-height-headline-medium) var(--td-font-family);
  --td-font-headline-large: var(--td-font-size-headline-large) /    var(--td-line-height-headline-large) var(--td-font-family);
  --td-font-display-medium: var(--td-font-size-display-medium) /    var(--td-line-height-display-medium) var(--td-font-family);
  --td-font-display-large: var(--td-font-size-display-large) /    var(--td-line-height-display-large) var(--td-font-family);
/* 字体颜色 */
  --td-text-color-primary: var(--td-font-white-1);
  --td-text-color-secondary: var(--td-font-white-2);
  --td-text-color-placeholder: var(--td-font-grwhiteay-3);
  --td-text-color-disabled: var(--td-font-white-4);
  --td-text-color-anti: #000;
  --td-text-color-brand: var(--td-brand-color);
  --td-text-color-link: var(--td-brand-color);
/* end 字体配置 */
  /* 圆角配置 */
  --td-radius-small: 2px;
  --td-radius-default: 3px;
  --td-radius-medium: 6px;
  --td-radius-large: 9px;
  --td-radius-extraLarge: 12px;
  --td-radius-round: 999px;
  --td-radius-circle: 50%;
/* end 圆角配置 */
  /* 阴影配置 */
  --td-shadow-1: 0 1px 10px rgba(0, 0, 0, .05),0 4px 5px rgba(0, 0, 0, 8%),0 2px 4px -1px rgba(0, 0, 0, 12%);
  --td-shadow-2: 0 3px 14px 2px rgba(0, 0, 0, .05),0 8px 10px 1px rgba(0, 0, 0, 6%),0 5px 5px -3px rgba(0, 0, 0, 10%);
  --td-shadow-3: 0 6px 30px 5px rgba(0, 0, 0, .05),0 16px 24px 2px rgba(0, 0, 0, 4%),0 8px 10px -5px rgba(0, 0, 0, 8%);
/* end 阴影配置 */
   /* 尺寸配置 */
  --td-size-1: 2px;
  --td-size-2: 4px;
  --td-size-3: 6px;
  --td-size-4: 8px;
  --td-size-5: 12px;
  --td-size-6: 16px;
  --td-size-7: 20px;
  --td-size-8: 24px;
  --td-size-9: 28px;
  --td-size-10: 32px;
  --td-size-11: 36px;
  --td-size-12: 40px;
  --td-size-13: 48px;
  --td-size-14: 56px;
  --td-size-15: 64px;
  --td-size-16: 72px;
  --td-comp-size-xxxs: var(--td-size-6);
  --td-comp-size-xxs: var(--td-size-7);
  --td-comp-size-xs: var(--td-size-8);
  --td-comp-size-s: var(--td-size-9);
  --td-comp-size-m: var(--td-size-10);
  --td-comp-size-l: var(--td-size-11);
  --td-comp-size-xl: var(--td-size-12);
  --td-comp-size-xxl: var(--td-size-13);
  --td-comp-size-xxxl: var(--td-size-14);
  --td-comp-size-xxxxl: var(--td-size-15);
  --td-comp-size-xxxxxl: var(--td-size-16);
  --td-pop-padding-s: var(--td-size-2);
  --td-pop-padding-m: var(--td-size-3);
  --td-pop-padding-l: var(--td-size-4);
  --td-pop-padding-xl: var(--td-size-5);
  --td-pop-padding-xxl: var(--td-size-6);
  --td-comp-paddingLR-xxs: var(--td-size-1);
  --td-comp-paddingLR-xs: var(--td-size-2);
  --td-comp-paddingLR-s: var(--td-size-4);
  --td-comp-paddingLR-m: var(--td-size-5);
  --td-comp-paddingLR-l: var(--td-size-6);
  --td-comp-paddingLR-xl: var(--td-size-8);
  --td-comp-paddingLR-xxl: var(--td-size-10);
  --td-comp-paddingTB-xxs: var(--td-size-1);
  --td-comp-paddingTB-xs: var(--td-size-2);
  --td-comp-paddingTB-s: var(--td-size-4);
  --td-comp-paddingTB-m: var(--td-size-5);
  --td-comp-paddingTB-l: var(--td-size-6);
  --td-comp-paddingTB-xl: var(--td-size-8);
  --td-comp-paddingTB-xxl: var(--td-size-10);
  --td-comp-margin-xxs: var(--td-size-1);
  --td-comp-margin-xs: var(--td-size-2);
  --td-comp-margin-s: var(--td-size-4);
  --td-comp-margin-m: var(--td-size-5);
  --td-comp-margin-l: var(--td-size-6);
  --td-comp-margin-xl: var(--td-size-7);
  --td-comp-margin-xxl: var(--td-size-8);
  --td-comp-margin-xxxl: var(--td-size-10);
  --td-comp-margin-xxxxl: var(--td-size-12);
/* end 尺寸配置 */
}