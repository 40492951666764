.sidebar {
  width: 240px;
  height: 100vh;
  background-color: var(--td-bg-color-container);
  border-right: 1px solid var(--td-component-border);
  padding-top: 16px;
  position: fixed;
  left: 0;
  top: 0;
}

.sidebar .t-menu {
  width: 100%;
}

.sidebar .t-menu__item {
  margin: 4px 8px;
  border-radius: 6px;
}

.sidebar .t-menu__item.t-is-active {
  background-color: var(--td-brand-color-1);
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: 64px;
  }
  
  .sidebar .t-menu__item-text {
    display: none;
  }
}