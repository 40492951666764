.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  /* background-color: var(--td-bg-color-container); */
  padding: 20px;
}

.login-card {
  width: 100%;
  max-width: 600px;
  padding: 24px;
  /* border: 0 solid var(--td-component-border); */
}

.login-title {
  text-align: center;
  margin-bottom: 24px;
  color: var(--td-text-color-primary);
}

.login-form {
  margin-top: 24px;
}

.qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
}

.qrcode-container p {
  margin-top: 16px;
  color: var(--td-text-color-secondary);
}

.login-footer {
  margin-top: 24px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .login-card {
    margin: 0 16px;
  }
}