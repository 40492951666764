/* 卡片全局样式 */
.t-card {
  background-color: var(--td-bg-color-container);
  border-color: var(--td-border-color-component);
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.1s ease;
}

/* 卡片hover效果 */
.t-card:hover {
  border-color: var(--td-bg-color-container-hover);
}

/* 统计卡片样式 */
.stats-card {
  height: 180px;
}

/* 统计数字样式 */
.t-statistic__content {
  color: var(--td-text-color-primary);
}

/* 统计标题样式 */
.t-statistic__title {
  color: var(--td-text-color-secondary);
}

/* 深色主题下的卡片样式调整 */
[data-theme="dark"] .t-card {
  background-color: var(--td-bg-color-container);
  border-color: var(--td-border-color-component);
  /* color: whitesmoke; */
}

[data-theme="dark"] .t-card:hover {
  border-color: var(--td-bg-color-container-hover);
}

/* 深色主题下的统计数字颜色 */
[data-theme="dark"] .t-statistic__content {
  color: var(--td-text-color-primary);
}

/* 深色主题下的统计标题颜色 */
[data-theme="dark"] .t-statistic__title {
  color: var(--td-text-color-secondary);
  /* color: whitesmoke; */
}

/* 统计数字特殊颜色 */
.t-statistic--blue .t-statistic__content {
  color: var(--td-brand-color);
}

/* 卡片内边距调整 */
.t-card__body {
  padding: 24px;
}