.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  /* background-color: var(--td-bg-color-container); */
  padding: 20px;
}

.register-card {
  width: 100%;
  max-width: 600px;
  padding: 24px;
}

.register-title {
  text-align: center;
  margin-bottom: 24px;
  color: var(--td-text-color-primary);
}

.register-content {
  margin: 24px 0;
}

.register-description {
  margin-bottom: 24px;
  color: var(--td-text-color-secondary);
  text-align: center;
  line-height: 1.5;
}

.register-footer {
  margin-top: 24px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .register-card {
    margin: 0 16px;
  }
}