/* 全局样式 */
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 链接样式 */
a {
  text-decoration: none;
  color: var(--td-brand-color);
}
a:hover {
  color: var(--td-brand-color-hover);
}

/* 数字显示样式 */
.t-card__body--number {
  font-size: 24px;
  font-weight: 500;
  color: var(--td-text-color-primary);
}

/* Input组件样式覆盖 */
.t-input {
  background-color: var(--td-bg-color-container);
  border-radius: 0;
  border: 1px solid var(--cus-border-color-input);
}
.t-input:hover {
  background-color: var(--td-bg-color-container-hover);
}
.t-input.t-is-disabled {
  background-color: var(--td-bg-color-container-disabled);
}

/* 表格样式优化 */
/* .t-table {
  background-color: var(--td-bg-color-container);
}
.t-table th {
  background-color: var(--td-bg-color-container-hover);
} */

/* 修改 MenuItem 样式 - 禁用状态只改变字体颜色 */
.t-menu__item.t-is-disabled {
  background-color: transparent !important;
  color: var(--td-text-color-disabled) !important;
}
/* 修改 MenuItem 悬停和激活状态 - 只改变字体颜色 */
.t-menu__item:not(.t-is-disabled):hover,
.t-menu__item.t-is-active {
  /* background-color: transparent !important; */
  /* color: var(--td-brand-color) !important; */
}

.t-tabs__header { 
  /* background-color: var(--td-bg-color-tabs-label)!important; */
  /* border: 1px solid var(--td-border-color-divider)!important; */
  margin-bottom: 12px!important;
}
/* .t-tabs__nav-item {
  border-bottom:  2px solid transparent !important;
  border-left: 2px solid transparent!important;
  border-right: 2px solid var(--td-border-color-divider)!important;
}
.t-tabs__nav--card {
  background-color: var(--td-bg-color-tabs-label) !important;
}
.t-tabs__nav-item.t-is-active {
  background-color: var(--td-bg-color-container) !important;
} */


/* 
.t-descriptions {
  border: 1px solid var(--td-border-color-divider)!important;
  background-color: var(--td-bg-color-container) !important;
}
.t-descriptions__label {
  border: 1px solid var(--td-border-color-divider)!important;
  background-color: var(--td-bg-color-container) !important;
}
.t-descriptions__content {
  border: 1px solid var(--td-border-color-divider)!important;
  background-color: var(--td-bg-color-container)!important;
} */

/* DateRangePicker 样式配置 */
/* .t-date-range-picker {
  background-color: var(--td-bg-color-container) !important;
  color: blueviolet;
}

.t-date-range-picker__panel-content {
  background-color: var(--td-bg-color-container) !important;
}

.t-date-range-picker__cell:hover {
  background-color: var(--td-bg-color-container-hover) !important;
}

.t-date-range-picker__cell.t-is-selected {
  background-color: var(--td-brand-color) !important;
  color: var(--td-text-color-anti) !important;
} */